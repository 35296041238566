<template>
  <validation-observer ref="form-user">
    <b-form class="p-2">
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          <b-form-group
            label="Avatar"
            label-for="user-avatar"
          >
            <BaseImageFileInput
              id="user-avatar"
              :value="model.avatar"
              @input="handleFileInputChange"
              @delete-image="avatarRemoved = true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Nombre"
            label-for="user-name"
          >
            <b-form-input
              id="user-name"
              :value="model.name"
              placeholder="Nombre"
              @input="update('name', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Apellidos"
            label-for="user-surname"
          >
            <b-form-input
              :value="model.surname"
              placeholder="Apellidos"
              name="surname"
              @input="update('surname', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <validation-provider
            v-slot="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="user-email"
            >
              <b-form-input
                id="user-email"
                ref="user-email-input"
                :value="model.email"
                placeholder="Email"
                :state="$getValidationState(validationContext) && emailInputState"
                @input="handleChangeEmailInput"
              />
              <small class="text-danger">{{ validationContext.errors[0] || emailError }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Telefono"
            label-for="user-phone"
          >
            <b-form-input
              id="user-phone"
              :value="model.phone"
              placeholder="Telefono"
              @input="update('phone', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Cargo"
            label-for="user-job"
          >
            <b-form-input
              id="user-job"
              :value="model.job"
              placeholder="Cargo"
              @input="update('job', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Departamento"
            label-for="user-department"
          >
            <b-form-input
              id="user-department"
              :value="model.department"
              placeholder="Departamento"
              @input="update('department', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="passwordFieldVisible"
          sm="6"
        >
          <validation-provider
            v-slot="validationContext"
            name="Contraseña"
            :rules="passwordRequired && passwordFieldVisible ? 'required|min:8' : 'min:8'"
          >
            <b-form-group
              label="Contraseña"
              label-for="user-password"
            >
              <b-input-group>
                <template #append>
                  <b-input-group-text>
                    <feather-icon
                      :icon="showPassword ? 'EyeIcon' : 'EyeOffIcon'"
                      :svg-classes="[ 'text-white' ,'h-5 w-5 mt-2']"
                      @click="showPassword = !showPassword"
                    />
                  </b-input-group-text>
                </template>
                <b-form-input
                  id="user-password"
                  :value="model.password"
                  :type="showPassword ? 'text' : 'password'"
                  placeholder="Contraseña"
                  counter
                  outlined
                  :state="$getValidationState(validationContext)"
                  @input="update('password', $event)"
                />
              </b-input-group>
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col sm="6">
          <validation-provider
            v-slot="validationContext"
            name="Rol"
            rules="required"
          >
            <b-form-group
              label="Rol"
              label-for="user-rol"
            >
              <BaseSelect
                id="user-rol"
                :value="model.role"
                label="display_name"
                placeholder="Selecciona un rol"
                :resource="$data.$constants.RESOURCES.RESOURCE_ROLES"
                :reduce="role => role.name"
                @input="update('role', $event)"
              />
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'
import BaseImageFileInput from '@/components/ui/file/BaseImageFileInput.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'UserForm',
  components: { BaseImageFileInput, BaseSelect },
  mixins: [FormMixin],
  props: {
    passwordFieldEnabled: {
      type: Boolean,
      default: false,
    },
    passwordRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPassword: false,
      emailError: '',
      emailInputState: true,
      avatarRemoved: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'getUser',
    }),
    passwordFieldVisible() {
      return this.$route.params.id === this.user.id || this.user.roles.some(role => role.name === 'super_admin') || this.passwordFieldEnabled
    },
  },
  methods: {
    validateForm() {
      return this.$refs['form-user'].validate()
    },
    isAvatarRemoved() {
      return this.avatarRemoved
    },
    handleUniqueEmailError(error) {
      this.$refs['user-email-input'].focus()
      this.emailInputState = false
      this.emailError = error.pop()
    },
    handleChangeEmailInput(value) {
      this.update('email', value)
      this.emailError = ''
      this.emailInputState = true
    },
    handleFileInputChange(file) {
      this.massUpdate({ ...this.model, avatar: file, myFile: [file] })
    },
  },
}
</script>

<style scoped>
</style>

<template>
  <BaseCard
    title="mi perfil"
    :loading="loading"
    @save-button-click="handleSaveButtonClick"
  >
    <UserForm
      ref="user-form"
      v-model="userModel"
      password-field-enabled
    />
  </BaseCard>
</template>

<script>
import UserForm from '@/components/users/form/UserForm.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import { mapActions, mapGetters } from 'vuex'
import UsersApi from '@/api/users-api'

export default {
  components: { BaseCard, UserForm },
  data() {
    return {
      loading: false,
      userModel: {},
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'getUser',
    }),
    userForm() {
      return this.$refs['user-form']
    },
  },
  mounted() {
    this.loadUser()
  },
  methods: {
    ...mapActions('auth', ['setUser']),
    async loadUser() {
      this.userModel = { ...this.user }
      this.userModel.role = this.user.roles && this.user.roles.length > 0 ? this.user.roles[0] : null
    },
    async handleSaveButtonClick() {
      const success = await this.userForm.validateForm()
      if (!success) {
        return
      }

      this.loading = true
      try {
        delete this.userModel.roles
        delete this.userModel.comments
        if (this.userForm.isAvatarRemoved()) {
          await UsersApi.deleteAvatar(this.userModel.id)
        }

        const response = await UsersApi.edit(this.userModel.id, this.userModel, false)
        this.setUser(response.data)
        this.$toast('Recurso actualizado con éxito.')
        await this.$router.go(-1)
      } catch (error) {
        this.handleError(error)
      }
      this.loading = false
    },
    handleError(error) {
      if (error.response.data?.email) {
        this.userForm.handleUniqueEmailError(error.response.data?.email)
      } else {
        console.error(error)
        this.$toast.error('Ocurrió un error al actualizar el recurso. Por favor inténtelo de nuevo mas tarde.')
      }
    },
  },
}
</script>

<style scoped>
</style>
